.dpkCursor {
    height: 40px;
    width: 40px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999999;
    pointer-events: none;

    &::before {
        content: '';
        transform: rotate(45deg);
        position: absolute;
        border-radius: 12px;
        width: 40px;
        height: 40px;
        background: #8888888d;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }
}
.hotline-icon-one-pc{
    z-index: 999;
    width: max-content;
    background: rgba(46, 40, 214, 0.834);
    border-radius: 50%;
    padding: 10px;
    animation: pulse 1.5s infinite; 
    
    position: fixed;
    bottom: 50px;
    right: 40px;
}
.hotline-icon-svg {
    width: 50px;
}
.hotline-icon-one-ios{
    z-index: 999;
    width: max-content;
    background: rgba(46, 40, 214, 0.834);
    border-radius: 50%;
    padding: 10px;
    animation: pulse 1.5s infinite; 
    position: fixed;
    bottom: 50px;
    right: 40px;
    display:none
}
.hotline-icon-one-android{
    z-index: 999;
    width: max-content;
    background: rgba(46, 40, 214, 0.834);
    border-radius: 50%;
    padding: 10px;
    animation: pulse 1.5s infinite; 
    position: fixed;
    bottom: 50px;
    right: 40px;
    display:none
}
.hotline-icon-two{
    z-index: 999;
    width: max-content;
    background: rgba(46, 40, 214, 0.834);
    border-radius: 50%;
    padding: 10px;
    animation: pulse 1.5s infinite; 
    position: fixed;
    bottom: 150px;
    right: 40px;
}
.hotline-icon-three{
    z-index: 999;
    width: max-content;
    background: rgb(255 255 255);
    border-radius: 50%;
    padding: 10px;
    animation: pulse 1.5s infinite; 
    position: fixed;
    bottom: 200px;
    left: 30px;
    right: 0;
}
.dpkCursor-hover {
    margin-left: -2px;
    margin-top: -2px;
    width: 6px;
    height: 6px;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999999999;
    pointer-events: none;
    &::before {
        content: '';
        transform: rotate(45deg);
        position: absolute;
        border-radius: 12px;
        border: none;
        transition:
            background 0.5s,
            box-shadow 0.5s
        ;
        box-shadow: 0px 0px 20px 18px #888888;
        margin-left: -20px;
        margin-top: -20px;
        width: 60px;
        height: 60px;

        /* scale:1.2; */
        background: #8888884d;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }
}

.header-sell-website {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 100;
    background: #ffffffd9;
    box-shadow: -1px 3px 18px #888888;
    /* background: linear-gradient(to right, #a8c6f1f7, #edf4fee5); */
}

.navbar-sell-website {
    display: flex;
    /* padding: 22px 10px; */
    align-items: center;
    max-width: 1200px;
    margin: 0 auto;
    justify-content: space-between;
}

.navbar-sell-website::before {
    display: unset !important;
    content: none !important
}

.navbar-sell-website::after {
    display: unset !important;
    content: none !important
}

.navbar-sell-website .hamburger-btn {
    display: none;
    color: black;
    cursor: pointer;
    font-size: 1.5rem;
}

.navbar-sell-website .logo {
    order: 1;
    gap: 10px;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.navbar-sell-website .logo-res {
    order: 1;
    gap: 10px;
    display: flex;
    align-items: center;
    text-decoration: none;
    display: none
}

.navbar-sell-website .logo-res img {
    width: 60px;
}

.navbar-sell-website .logo img {
    width: 166px;
    padding: 10px 0;
    height: 86px;
    object-fit: cover;

}

.navbar-sell-website .links {
    order: 2;
    display: flex;
    gap: 35px;
    margin: 0;
    list-style: none;
    align-items: center;
}

.navbar-sell-website .links .dropdown {
    position: relative;
}

.navbar-sell-website .links .dropdown .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background: #9cc4fde3;
    display: none;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
    min-width: 150px;
    padding: 10px 0;
}

.navbar-sell-website .links .dropdown:hover .dropdown-menu {
    display: flex;
    animation: fade-in 0.3s ease-in-out forwards;
}

.navbar-sell-website .links .dropdown .dropdown-menu li {
    padding: 10px;
}

.navbar-sell-website .links .dropdown .dropdown-menu li:hover {
    /* padding: 10px 15px; */
    background: #468df13e;
    color: white;
}

.navbar-sell-website .links .dropdown .dropdown-menu li a:hover {
    padding: 10px 20px;
    background: unset;
    color: white !important;
}

.navbar-sell-website .links .dropdown:hover .dropdown-menu .submenu-menu {
    display: none;
    animation: fade-in 0.3s ease-in-out forwards;
}

.navbar-sell-website .links .dropdown .dropdown-menu .submenu-menu li {
    padding: 10px 20px;
}

.navbar-sell-website .links .dropdown .dropdown-menu .submenu-menu li:hover {
    padding: 13px 20px;
    background: #9cc4fda7;
    color: white;
}

.navbar-sell-website .links .dropdown .dropdown-menu .submenu-menu li a:hover {
    padding: 10px 0px;
    background: unset;
}

.navbar-sell-website .links .dropdown {
    position: relative;
}

.navbar-sell-website .links .dropdown .dropdown-menu>li:hover .submenu-menu {
    display: block;

}

/* Thêm animation cho submenu-menu */
.navbar-sell-website .links .dropdown .submenu-menu {
    list-style-type: none;
    /* flex-direction: row; */
    position: absolute;
    /* top: 0%; */
    left: 100%;
    background: #9cc4fde3;
    display: none;
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 1;
    min-width: 200px;
    padding: 10px 0;
    margin-top: -48px;
    animation: fade-in 0.3s ease-in-out forwards;
}

/* Hiển thị submenu-menu khi hover vào dropdown hoặc submenu 1 */
.navbar-sell-website .links .dropdown:hover .submenu-menu,
.navbar-sell-website .links .dropdown .dropdown-menu li:hover .submenu-menu {
    display: flex;
    animation: fade-in-submenu 0.3s ease-in-out forwards;
}

/* Khi hover vào các mục trong submenu-menu, thay đổi màu nền */
.submenu-menu li:hover {
    /* background: linear-gradient(to right, #9cc4fda7, #9cc4fd24); */
}

/* Keyframes cho animation */
@keyframes fade-in {
    from {
        opacity: 0;
        transform: translateY(-10px);
        /* Dịch chuyển lên trên */
    }

    to {
        opacity: 1;
        transform: translateY(0);
        /* Khôi phục vị trí ban đầu */
    }
}

.navbar-sell-website .close-btn {
    position: absolute;
    right: 20px;
    top: 20px;
    display: none;
    color: white;
    cursor: pointer;
}

.navbar-sell-website .back-btn {
    position: absolute;
    left: 20px;
    top: 20px;
    display: none;
    color: black;
    cursor: pointer;
}

.navbar-sell-website .links a {
    color: black;
    font-size: 1rem;
    /* font-weight: 500; */
    text-decoration: none;
    transition: 0.1s ease;
}

.navbar-sell-website .links a:hover {
    color: #151515a6;
}

.navbar-sell-website .logged-btn {
    display: flex;
    order: 3;
    border: none;
    outline: none;
    color: black;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 15px;
    cursor: pointer;
    transition: 0.15s ease;
    align-items: center;
}

.navbar-sell-website .logged-btn .logged-content {
    color: black;
    text-decoration: none;
    display: flex;
    font-size: 16px;
    align-items: center;
    margin-right: 10px;
}

.navbar-sell-website .logged-btn .logout-content {
    display: flex;
    align-items: center;
    margin-left: 10px;
    /* margin-right: 10px; */
}

.navbar-sell-website .logged-btn .cart-content {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-right: 10px;
}

.navbar-sell-website .logged-btn .cart-content {
    position: relative;
}

.navbar-sell-website .logged-btn .cart-content img {
    width: 25px;
    height: 25px;
}

.navbar-sell-website .logged-btn .cart-content .number {
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: red;
    color: white;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    font-size: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
}

.navbar-sell-website .logged-btn .logged-content .logged-text {
    margin-left: 10px;
    white-space: nowrap;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 60px
}

.navbar-sell-website .login-btn {
    order: 3;
    border: none;
    outline: none;
    background: #151515;
    /* opacity: 0.1; */
    color: white;
    font-size: 13px;
    font-weight: 600;
    padding: 10px 18px;
    border-radius: 15px;
    cursor: pointer;
    transition: 0.15s ease;
}

.navbar-sell-website .login-btn:hover {
    background: #151515a6;
}

.navbar-sell-website .button-portfolio-website {
    display: none
}

.navbar-sell-website .submenu-menu-responsive {
    display: none
}

.navbar-sell-website .search-icon {
    filter: invert(1);
    width: 20px;
    cursor: pointer
}

.openBtn {
    background: linear-gradient(to right, #9cc4fda7, #9cc4fd24);
    border: none;
    padding: 10px 15px;
    font-size: 20px;
    cursor: pointer;
}

.openBtn:hover {
    background: #bbb;
}

.overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 1059;
    top: 0;
    left: 0;
    background: linear-gradient(to right, #a8c6f1fc, #edf4fef6);
    overflow-y: scroll;
}

.overlay-content {
    position: relative;
    width: 80%;
    text-align: center;
    margin-top: 30px;
    margin: auto;
}

.overlay .closebtn {
    position: absolute;
    top: 20px;
    right: 45px;
    font-size: 60px;
    cursor: pointer;
    color: black;
}

.overlay .closebtn:hover {
    color: #ccc;
}

.overlay input[type=text] {
    padding: 15px;
    font-size: 13px;
    border: none;
    float: left;
    width: 80%;
    background: unset;
    border: 1px solid #4b93f8
}

/* 
.overlay input[type=text]:hover {
    background: #f1f1f1;
} */

.overlay button {
    float: left;
    width: 20%;
    padding: 13px;
    font-size: 17px;
    border: none;
    cursor: pointer
}

.overlay button:hover {
    background: #bbb;
}

@media (max-width: 950px) {
    .hotline-icon-one-pc{
        display:flex;
    }
    .hotline-icon-one-ios{
        display:flex
    }
    .hotline-icon-one-android{
        display:flex
    }
    .dpkCursor{
        display:none
    }
    .dpkCursor-hover{
        display:none
    }
    .navbar-sell-website {
        padding: 0 10px;
    }
    .navbar-sell-website .logo-res img {
        width: 160px;
    }
    .navbar-sell-website :is(.hamburger-btn, .close-btn) {
        display: block;
    }

    .navbar-sell-website :is(.hamburger-btn, .back-btn) {
        display: block;
    }

    .navbar-sell-website .links .dropdown:hover .dropdown-menu {
        animation: unset
    }

    .navbar-sell-website .links .dropdown .dropdown-menu li:hover {
        background: unset;
    }

    .navbar-sell-website .links .dropdown .dropdown-menu li a {
        background: unset;
    }

    .navbar-sell-website .links .dropdown .dropdown-menu li a:hover {
        color: #4b93f8;
        /* background: linear-gradient(to right, #9cc4fda7, #9cc4fd24); */
    }

    .active-submenu-menu-responsive li a:hover {
        padding: 0px;
        margin: 0px
    }

    .navbar-sell-website .active-portfolio-false {
        display: flex !important
    }

    .navbar-sell-website .active-portfolio-true {
        display: none !important
    }

    .navbar-sell-website .deactivate-portfolio-false {
        display: none !important
    }

    .navbar-sell-website .deactivate-portfolio-true {
        display: flex !important
    }

    .navbar-sell-website .active-portfolioCategory-false {
        display: flex !important
    }

    .navbar-sell-website .active-portfolioCategory-true {
        display: none !important
    }

    .navbar-sell-website .deactivate-portfolioCategory-false {
        display: none !important
    }

    .navbar-sell-website .deactivate-portfolioCategory-true {
        display: flex !important
    }

    .navbar-sell-website .button-portfolio-website {
        padding-left: 10px;
        display: flex
    }

    .navbar-sell-website .submenu-menu {
        display: none !important
    }

    .navbar-sell-website .submenu-menu-responsive {
        display: none !important
    }

    .navbar-sell-website .active-submenu-menu-responsive {
        display: flex !important;
        top: 100%;
        left: 0;
        background: unset;
        display: none;
        flex-direction: column;
        border-radius: 5px;
        /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
        z-index: 1;
        min-width: 150px;
        list-style-type: none;
        /* padding: 10px 0; */
    }

    .navbar-sell-website .logo-res {
        display: block
    }

    .navbar-sell-website .logo {
        display: none;
    }

    .navbar-sell-website .links {
        position: fixed;
        top: 0;
        z-index: 10;
        left: -100%;
        display: block;
        height: 100vh;
        overflow-y: auto;
        width: 100%;
        padding-top: 60px;
        text-align: center;
        background: #fff;
    }

    .navbar-sell-website .links.show-menu {
        left: 0;
    }

    .navbar-sell-website .links a {
        display: inline-flex;
        margin: 20px 0;
        font-size: 1.2rem;
        color: #000;
    }

    .navbar-sell-website .links a:hover {
        color: #4b93f8;
    }

    .navbar-sell-website .login-btn {
        font-size: 0.9rem;
        padding: 7px 10px;
    }

    .navbar-sell-website .links {
        position: absolute;
        left: 0;
        width: 100%;
        background: #888888ec;
        flex-direction: column;
        display: none;
    }

    .navbar-sell-website .links.show-menu {
        display: flex;
    }

    .navbar-sell-website .links a {
        color: white;
        font-size: 16px;
        font-weight: 500;
        text-decoration: none;
        /* padding: 10px 20px; */
    }

    .navbar-sell-website .links .dropdown .dropdown-menu {
        display: block;
        position: unset;
        background: unset;
        border: none;
        box-shadow: none;
    }

    .navbar-sell-website .links .dropdown .dropdown-menu li {
        border: none
    }

    .navbar-sell-website .links .dropdown .dropdown-menu li a:hover {
        cursor: pointer;
        margin: 20px 0;
    }

}