.footer {
    background: #f0f0f078 !important;
    height: auto;
    padding: 0 0 10px 0 !important;
    box-shadow: 1px 2px 20px 0px #888888;
    .container {
        .logo {
            display: inline-flex;
            align-items: center;
            gap: 20px;
            .svg-logo {
                width: 80px;
                height: 80px;
                fill: rgba(255, 255, 255, 0);
            }
            .text-logo {
                width: auto;
                color: #fff;
                font-size: 16px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
                .text-logo-color {
                    color: red;
                }
            }
        }
        .content {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 15px;
            .content-hotline {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                gap:5px;
                .content-desc {
                    color: #000;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-transform: uppercase;
                }
            }
            .content-commitment {
                width: 598px;
                
            }
        }
    }
}
@media only screen and (max-width: 600px) {
    .footer {
        .container {
            .content {
                display: flex;
                flex-direction: column;
                align-items: unset;
                gap: 15px;
                .content-hotline {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                }
                .content-commitment {
                    width: auto;
                }
            }
        }
    }
}
