.section-sell-website-homepage {
    .swiper {
        width: 100%;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 400px;
        height: 600px;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
    }
    .swiper-3d .swiper-slide-shadow-left {
        background-image: linear-gradient(to left, rgb(0 0 0 / 11%), rgba(0, 0, 0, 0));
    }
    .swiper-3d .swiper-slide-shadow-right {
        background-image: linear-gradient(to right, rgb(0 0 0 / 11%), rgba(0, 0, 0, 0));
    }
    .section-sell-website-banner {
        // background-image: url("../../../../images/Sell-Website/banner.png");
        // height: 768px;
        // width: 100%;
        // background-size: cover;
        // background-position: center;
        // .section-sell-website-banner-box {
        //     display: flex;
        //     flex-direction: column;
        //     height: inherit;
        //     justify-content: center;
        //     animation:
        //         moveUp 0.5s ease-in-out,
        //         fadeInOpacity 0.5s ease-in-out;
        //     .section-sell-website-banner-title {
        //         padding: 20px 60px;
        //         color: white;
        //         font-size: 3.5rem;
        //     }
        //     .section-sell-website-banner-subtitle {
        //         padding: 0 60px;
        //         width: 65vw;
        //         color: white;
        //         font-size: 2.5rem;
        //     }
        //     .section-sell-website-banner-content {
        //         padding: 0 60px;
        //         width: 60vw;
        //         color: white;
        //         font-size: 1.5rem;
        //     }
        //     .section-sell-website-banner-list {
        //         padding: 10px 60px;
        //         width: 60vw;
        //         display: flex;
        //         flex-wrap: wrap;
        //         color: white;
        //         gap: 10px;
        //         font-size: 1.3rem;
        //         > div {
        //             width: 45%;
        //         }
        //     }
        // }
    }

    .section-sell-website-slide {
        .wrapper {
            width: 100%;
            height: 100%;
            // background-size: cover;
            position: relative;
            animation: fadeInSlide 0.5s ease-in-out !important;
        }

        .selectors {
            position: absolute;
            top: 0;
            left: 0;
            width: 30px;
            background: rgba(0, 0, 0, 0.1);
            height: inherit;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            z-index: 1;

            .selector {
                border: 1px solid #fff;
                background: none;
                width: 11px;
                height: 11px;
                border-radius: 50%;
                margin: 5px 0;
                opacity: 0.6;
                cursor: pointer;
                transition:
                    background 0.3s ease-in-out,
                    opacity 0.3s ease-in-out;

                &.active,
                &:hover {
                    background: #fff;
                    opacity: 0.9;
                }
            }
        }

        .panel {
            /* CSS cho panel khi không active */
            width: 40vw;
            height: 80vh;
            color: #fff;
            box-shadow: 5px 0 25px 0 rgba(0, 0, 0, 0.3);
            transition: all 500ms ease-in-out;
            display: none;
            flex-direction: column;
            justify-content: center;

            .panel-video {
                animation: fadeInSlide 0.5s ease-in-out;
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                z-index: -1;
            }

            &.active {
                /* CSS cho panel khi active */
                display: flex;

                .panel-header,
                .panel-info {
                    margin: 0;
                    padding: 1.3rem 3.5rem;
                    animation:
                        moveUp 0.5s ease-in-out,
                        fadeInOpacity 0.5s ease-in-out;
                }

                .panel-header {
                    padding-top: 2em;
                    font-weight: normal;
                    font-size: 2.5rem;
                    text-transform: capitalize;
                }

                .panel-info {
                    font-size: 1.2rem;
                    line-height: 1.8em;
                    opacity: 1;
                }
                .panel-button {
                    width: fit-content;
                    border-radius: 15px;
                    margin: 1.3rem 0.5rem 1.3rem 3.5rem;
                    padding: 0.8em 1.3em;
                    background: none;
                    border: 1px solid #fff;
                    color: #fff;
                    cursor: pointer;
                    font-size: 0.8rem;
                    font-weight: 700;
                    text-transform: uppercase;
                    transition:
                        box-shadow 0.5s ease-in-out,
                        color 0.5s ease-in-out;
                    animation:
                        moveUp 0.5s ease-in-out,
                        fadeInOpacity 0.5s ease-in-out;
                    &:hover {
                        box-shadow: inset 0 0 1.5em 1.5em #fff;
                        color: black !important;
                    }

                    &:focus {
                        outline: none;
                        // color: #000;
                    }
                }
            }
        }

        @keyframes moveUp {
            from {
                transform: translateY(40px);
            }
            to {
                transform: translateY(0);
            }
        }

        @keyframes fadeInOpacity {
            from {
                opacity: 0;
            }
            to {
                opacity: 1;
            }
        }
    }
    .section-sell-website-question {
        align-items: center;
        max-width: 1200px;
        margin: 0 auto;
        padding: 30px 0;
        .trevor {
            // transition: all 1s;
        }

        .section-sell-website-question-above .image-trevor {
            cursor: pointer;
            overflow: hidden;
            border-radius: 15px;
        }

        .section-sell-website-question-above .image-trevor img {
            transition: all 1s;
            transform-origin: center center;
        }

        .section-sell-website-question-above .image-trevor:hover img {
            transform: scale(1.2);
        }
        .section-sell-website-benefit-above {
            .section-sell-website-benefit-title {
                text-transform: uppercase;
                text-align: center;
                font-size: 2rem;
                &::after {
                    content: "";
                    display: table;
                    clear: both;
                    width: 100%;
                    height: 50px;
                    background-repeat: no-repeat;
                    background-position-x: center;
                    background-position-y: bottom;
                    padding-top: 0;
                }
            }
            .title-quanlity {
                display: flex;
                justify-content: center;
                margin: 30px;
                color: #000;
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
            }
            .quanlity-under {
                cursor: pointer;
                margin-bottom: 25px;
                .quanlity-under-content {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    gap: 5px;
                    .quanlity-under-icon {
                        width: 80px;
                        height: 80px;
                    }
                    .quanlity-under-name {
                        color: black;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        text-transform: uppercase;
                    }
                    .quanlity-under-desc {
                        color: #000;
                        text-align: center;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
            .content-quanlity {
                color: #000;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
        @media only screen and (max-width: 600px) {
            .title-quanlity {
                display: flex;
                justify-content: center;
                margin: 20px 0;
                color: #000;
                font-size: 20px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
            }
        }

        .section-sell-website-question-above {
            .section-sell-website-question-title {
                text-transform: uppercase;
                text-align: center;
                font-size: 3rem;
                &::after {
                    content: "";
                    display: table;
                    clear: both;
                    width: 100%;
                    height: 50px;
                    background-repeat: no-repeat;
                    background-position-x: center;
                    background-position-y: bottom;
                    padding-top: 0;
                }
            }
            .section-sell-website-question-subtitle {
                text-align: center;
                padding: 10px 10px 20px 10px;
            }
        }
        .section-sell-website-question-below {
            display: flex;
            // gap:20px;
            .section-sell-website-question-below-left {
                display: flex;
                flex-direction: column;
                gap: 20px;
                // padding: 0 20px 0 0;
                .section-sell-website-question-below-left-box {
                    cursor: pointer;
                    display: flex;
                    // gap: 20px;
                    .section-sell-website-question-below-left-img {
                        img {
                            border-radius: 15px;
                        }
                    }
                    .section-sell-website-question-below-left-content {
                        .section-sell-website-question-below-left-title {
                            font-size: 1.5rem;
                        }
                    }
                }
            }
        }
        .section-sell-website-price {
            display: flex;
            justify-content: center;
            gap: 10px;
            .section-sell-website-price-box {
                cursor: pointer;
                width: 30%;
                border: 0.5px solid #070707a1;
                border-radius: 15px;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 0;
                // margin: 20px;
                .section-sell-website-price-above {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    border-radius: 15px;
                    background: #070707a1;
                    color: white;
                    padding: 10px;

                    .section-sell-website-price-above-title {
                        font-size: 1.5rem;
                        text-transform: uppercase;
                    }
                    .section-sell-website-price-above-button {
                        font-size: 1rem;
                        text-transform: uppercase;
                    }
                }
                .section-sell-website-price-below {
                    display: flex;
                    flex-direction: column;
                    padding: 10px;
                    width: 100%;
                    .content-table-prices {
                        display: flex;
                        justify-content: space-between;
                        padding: 10px;
                        background: rgba(255, 255, 255, 0.53);
                        .price-table {
                            color: red;
                        }
                    }
                    .content-table-prices:last-child {
                        border-radius: 0 0 15px 15px;
                    }
                    .section-sell-website-price-below-title {
                        font-size: 1.5rem;
                        text-transform: uppercase;
                        text-align: center;
                    }
                    .section-sell-website-price-below-subtitle {
                        padding: 10px 0;
                        font-size: 1rem;
                    }
                }
            }
        }
        .services-image {
            // padding:15px;
            // width: 650px;
            // height: 452px;
            border-radius: 15px;
        }
        .list-services {
            width: 550px;
            height: auto;
            flex-shrink: 0;
            border-radius: 15px;
            background: #fff;
            padding-bottom: 10px;
            .title-services {
                margin: 0 25px 25px 25px;
                padding-left: 10px;
                border-left: 4px solid black;
                color: #000;
                font-size: 30px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-transform: uppercase;
            }
            .content-services {
                display: flex;
                align-items: center;
                gap: 10px;
                margin: 0 25px 10px 25px;
                .services-icon {
                    width: 35px;
                    height: 35px;
                }
                .services-icon-content {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    color: #000;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }
        // .cars {
        .section-sell-website-car {
            .title-cars {
                padding-left: 10px;
                border-left: 4px solid red;
                .title-cars-above {
                    color: #000;
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                    text-transform: uppercase;
                }
                .title-cars-under {
                    color: #000;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
            .list-cars {
                display: flex;
                align-items: center;
                margin: 25px 0;
                .slick-list {
                    .slick-track {
                        display: flex;
                        align-items: center;
                    }
                }
                .item-cars {
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100%;
                    .cars-image-container {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        height: 100%; /* Đảm bảo slide chiếm đầy đủ chiều cao của container */
                    }
                    .item-name-cars {
                        display: flex;
                        justify-content: center;
                        padding: 10px 0;
                        color: #000;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: normal;
                        text-transform: uppercase;
                    }
                    .cars-image {
                        width: 100%;
                        height: auto;
                        border-radius: 30px;
                    }
                }
            }
        }
        // }
    }
}
@media only screen and (max-width: 950px) {
    .section-sell-website-slide {
        .panel {
            width: 100% !important;
            height: 100% !important;
            // height: 80vh;
            &.active {
                /* CSS cho panel khi active */
                display: flex;

                .panel-header,
                .panel-info {
                    margin: 0;
                    padding: 20px 3.5rem !important;
                    animation:
                        moveUp 0.5s ease-in-out,
                        fadeInOpacity 0.5s ease-in-out;
                }

                .panel-header {
                    font-size: 1.6rem !important;
                }

                .panel-info {
                    font-size: 0.8rem !important;
                    // line-height: 1.8em;
                    opacity: 1;
                }
                .panel-button {
                    margin: 1.3rem 0.5rem 1.3rem 3.5rem !important;
                }
            }
        }
    }
    .section-sell-website-question {
        .section-sell-website-question-title {
            font-size: 1.6rem !important;
            padding: 10px !important;
            &::after {
                margin: 0 auto !important;
                width: 90% !important;
            }
        }
        .section-sell-website-question-below {
            .section-sell-website-question-below-left {
                padding:20px;
                .section-sell-website-question-below-left-box {
                    display: block !important;
                    gap: 20px;
                    .section-sell-website-question-below-left-img {
                        img {
                            width: 100%;
                            height: max-content;
                            border-radius: 15px;
                        }
                        padding: 15px !important;
                    }
                    .section-sell-website-question-below-left-content {
                        .section-sell-website-question-below-left-title {
                            font-size: 1.5rem;
                            display:flex;
                            justify-content: center;
                        }
                        .section-sell-website-question-below-left-subtitle{
                            text-align: center;
                        }
                    }
                }
            }
            .list-services{
                .title-services{
                    font-size: 1.6rem;
                }
                .content-services{
                    .services-icon{
                        width: 30px !important;
                    }
                }
            }
        }
    }
    .slider {
        width:100% !important;
        height: 230px !important;
        .slider__slide-subheading{
            font-size: 13px !important;
            color:white !important;
        }
        .slider__slide-heading{
            font-size: 20px !important;
        }
        &__control {
            width: 30px !important;
            height: 30px !important;
            margin-top: 30px / -2 !important;
            &:before {
                width: 10px !important;
                height: 10px !important;
                margin-left: -5px !important;
                margin-top: -5px !important;
            }
        }

    }
    .section-sell-website-price {
        display: flex !important;
        flex-direction: column;
        gap: 20px !important;
        padding: 0 20px !important;
        .section-sell-website-price-box {
            width: 100% !important;
            .section-sell-website-price-above {
                .section-sell-website-price-above-title {
                    font-size: 1.5rem;
                    text-transform: uppercase;
                    text-align: center;
                }
                .section-sell-website-price-above-button {
                    font-size: 1rem;
                    text-transform: uppercase;
                }
            }
        }
    }
    .section-sell-website-benefit-above{
        padding:20px !important;
        .section-sell-website-benefit-title{
            font-size: 1.2rem !important;
        }
    }
}
@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes fadeInSlide {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
$numOfParts: 4;
$animTime: 1s;
$stagger: 0.08s;
$sliderReadyTrans: all $animTime/2 $animTime;
$maxLettersStagger: 6;
$letterStagger: 0.1s;

.slider {
    overflow: hidden;
    position: relative;
    height: 100vh;
    color: #fff;

    @mixin sliderReady {
        .slider.s--ready & {
            @content;
        }
    }

    &__top-heading {
        z-index: $numOfParts * 3;
        position: absolute;
        left: 0;
        top: 100px;
        width: 100%;
        text-align: center;
        font-size: 16px;
        text-transform: uppercase;
        letter-spacing: 2.5px;
        transition: $sliderReadyTrans;
        transform: translateY(-30px);
        opacity: 0;

        @include sliderReady {
            transform: translateY(0);
            opacity: 1;
        }
    }

    &__slides {
        position: relative;
        height: 100%;
    }

    &__slide {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        pointer-events: none;

        &.s--active {
            pointer-events: auto;
        }

        @mixin slidePrev {
            .slider__slide.s--prev & {
                @content;
            }
        }

        @mixin slideActive {
            .slider__slide.s--active & {
                @content;
            }
        }

        &-content {
            z-index: $numOfParts + 2;
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 100%;
            text-transform: uppercase;
            line-height: 1;
        }

        @mixin subTextsActiveSlide {
            opacity: 0;
            transition: $animTime/2;

            @include slideActive {
                transition-delay: $animTime * 0.65;
                opacity: 1;
                transform: translateY(0);
            }
        }

        &-subheading {
            margin-bottom: 20px;
            font-size: 24px;
            letter-spacing: 2px;
            transform: translateY(20px);

            @include subTextsActiveSlide;
        }

        &-heading {
            $fontSize: 60px;

            display: flex;
            margin-bottom: 20px;
            font-size: $fontSize;
            letter-spacing: 12px;

            span {
                display: block;
                opacity: 0;
                transform: translateY($fontSize * -1);
                transition: all $animTime/3;

                @include slidePrev {
                    transform: translateY($fontSize);
                }

                @include slideActive {
                    opacity: 1;
                    transform: translateY(0);
                }

                @for $i from 1 through $maxLettersStagger {
                    &:nth-child(#{$i}) {
                        $delay: $letterStagger * ($i - 1);

                        transition-delay: $delay;

                        @include slideActive {
                            transition-delay: $delay + $animTime/3;
                        }
                    }
                }

                &:nth-child(n + #{$maxLettersStagger + 1}) {
                    $delay: $letterStagger * $maxLettersStagger;

                    transition-delay: $delay;

                    @include slideActive {
                        transition-delay: $delay + $animTime/3;
                    }
                }
            }
        }

        &-readmore {
            position: relative;
            font-size: 14px;
            text-transform: lowercase;
            backface-visibility: hidden;
            transform: translateY(-20px);
            cursor: pointer;

            @include subTextsActiveSlide;

            &:before {
                content: "";
                position: absolute;
                left: -2px;
                top: -3px;
                width: calc(100% + 4px);
                height: calc(100% + 6px);
                background: rgba(255, 255, 255, 0.4);
                transform: scaleX(0.3);
                transform-origin: 0 50%;
                transition: transform 0.3s;
            }

            &:hover:before {
                transform: scaleX(1);
            }
        }

        &-parts {
            position: absolute;
            left: 0;
            top: 0;
            display: flex;
            width: 100%;
            height: 100%;

            &:after {
                content: "";
                z-index: $numOfParts + 1;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                // background: rgba(0, 0, 0, 0.1);
            }
        }

        &-part {
            $partW: (100vw / $numOfParts);

            position: relative;
            width: percentage(1 / $numOfParts);
            height: 100%;

            $partRef: &;
            $imageFadeAT: $animTime/4;

            &-inner {
                overflow: hidden;
                position: relative;
                width: 100%;
                height: 100%;
                background-size: 0 0;
                background-repeat: no-repeat;
                transition: transform $animTime/2 ease-in-out;

                &:before {
                    content: "";
                    position: absolute;
                    width: 100vw;
                    height: 100%;
                    background-image: inherit;
                    background-size: cover;
                    background-position: center center;
                    transition: opacity $imageFadeAT;
                    opacity: 0;
                }

                @for $i from 1 through $numOfParts {
                    #{$partRef}:nth-child(#{$i}) & {
                        $delayOut: ($numOfParts - $i) * $stagger;
                        $delayIn: $i * $stagger + $animTime/5;

                        z-index: $numOfParts - $i;
                        transition-delay: $delayOut;
                        transform: translateX(percentage($i / $numOfParts * -1.3));

                        @include slideActive {
                            transition-delay: $delayIn;
                        }

                        &:before {
                            left: $partW * ($i - 1) * -1;
                            transition-delay: $delayOut + $imageFadeAT/2;

                            @include slideActive {
                                transition-delay: $delayIn;
                            }
                        }
                    }
                }

                @include slideActive {
                    transform: translateX(0);
                    transition-timing-function: ease;

                    &:before {
                        opacity: 1;
                    }
                }
            }
        }
    }

    &__control {
        $size: 50px;

        z-index: 100;
        position: absolute;
        left: 50px;
        top: 50%;
        width: $size;
        height: $size;
        margin-top: $size/-2;
        border-radius: 50%;
        background: rgba(255, 255, 255, 0.4);
        transform: translateX($size * -1);
        opacity: 0;
        transition: $sliderReadyTrans;
        cursor: pointer;

        &:before {
            content: "";
            position: absolute;
            left: 50%;
            top: 50%;
            width: 20px;
            height: 20px;
            margin-left: -10px;
            margin-top: -10px;
            border: 2px solid #000;
            border-bottom: none;
            border-right: none;
            transform: translateX(5px) rotate(-45deg);
        }

        &--right {
            left: auto;
            right: 50px;
            transform: translateX($size);

            &:before {
                transform: translateX(-5px) rotate(135deg);
            }
        }

        @include sliderReady {
            transform: translateX(0);
            opacity: 1;
        }
    }
}
.responsive-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
}

.with-margin {
    margin-bottom: 10px; /* Đặt khoảng cách dưới ảnh */
}

.caption {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    color: #fff;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
}
